<template lang="">
    <div >
        <div v-for="(cctv, index) in cctvs"
        :key="index">
            <canvas :id="cctv._id" ></canvas>
        </div>
    </div>
</template>
<script>
import JSMpeg from 'jsmpeg-player'
import axios from 'axios'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import {
  BCard, BCardTitle, BTable, BCol, BRow, BPagination, BFormCheckbox, BFormTextarea, BButton, BSpinner
} from 'bootstrap-vue'
export default {
    components: {
        BCard,
        BSpinner
    },
    data: () => {
        return {
            cctvs: [],
            players: [],
            streamURL: `https://rtsp.e-farm.site/?port=${4000}`,
            stopTimeoutId: null,
            fullScreen: false,
        }
    },
    mounted() {
        this.findInfo()
    },
    created() {
        this.stopTimeoutId = setTimeout(() => {
            this.allStreamStop()

            setTimeout(() => {
                this.$router.push({ name: 'remote-control' })
            }, 1000 * 2);
        }, 1000 * 60 * 10);
    },
    watch: {

    },
    beforeDestroy() {
        clearTimeout(this.stopTimeoutId);
        this.allStreamStop();
        //window.removeEventListener('orientationchange', this.handleOrientationChange);
    },
    methods: {
        async findInfo() {
            this.cctvs = [];
            const farms = await store.getters['farm/getFarmSelect'];
            const requests = farms.map(async farm => store.dispatch('cctv/fetchCCTVs', {
                userId: await getUserData().id,
                farmId: farm.value
            }));

            const responses = await Promise.all(requests);
            responses.forEach(response => {
                if (response.data.length >= 1) {
                    response.data.forEach(async cctv => {
                        cctv.farm = cctv.farmId.name;
                        this.cctvs.push(cctv);
                        cctv.rtspUrl = `rtsp://${cctv.account}:${cctv.password}@${cctv.rtspUrl}`;
                        let res = await axios.post(`${this.streamURL}/start`, cctv);
                        if (res.data.result) {
                            cctv.port = res.data.port;
                            this.stream(cctv);
                        }
                    });
                }
            });

            if (this.cctvs.length === 0) {
                this.toastMsg('warning', '알림', `CCTV 등록이 필요`)
                setTimeout(() => {
                    this.$router.push({ name: 'remote-control' });
                }, 1000);
            }
        },
        stopStream(port) {
            axios.get(`${this.streamURL}/stop/${port}`)
            let idx = this.players.findIndex(e => e.port === port)
            if(idx > -1) {
                this.players[idx].vPlayer.stop()
                this.players[idx].vPlayer.destroy()
                this.players.splice(idx, 1)
            }
            
        },
        allStreamStop() {
            
            if(this.players.length > 0) {
                for(let data of this.players) {
                    axios.get(`${this.streamURL}/stop/${data.port}`)
                    data.vPlayer.stop()
                    data.vPlayer.destroy()
                }
                this.toastMsg('info', '알림', `CCTV Off`)
            }
            this.players = []
              
        },
        async stream(cctv) {
            try {
                const canvas = document.getElementById(`${cctv._id}`);
                if (canvas) {
                    canvas.style.cssText = `
                        width: 100%;
                        background-color: black;
                        border-radius: 0.4rem;
                    `;
                    canvas.addEventListener('click', e => {
                        e.preventDefault();
                        this.toastMsg('info', '정보', `${cctv.name} CCTV 영상`)
                    });
                    
                    const player = new JSMpeg.Player(`wss://rtsp.e-farm.site/?port=${cctv.port}`, {
                        poster: '@/assets/logo.png',
                        disableGl: true,
                        loop: true,
                        canvas,
                        autoplay: true
                    });
                    this.players.push({ id: cctv._id, port: cctv.port, vPlayer: player });
                    this.toastMsg('info', '알림', `${cctv.name} CCTV On`)
                }
            } catch (error) {
                console.error("Stream failed: ", error);
                this.toastMsg('error', '에러', `${cctv.name} Err`)
            }
        },
/*         toggleFullscreen(element) {
            if (!document.fullscreenElement) {
                // 전체 화면으로 전환
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) { // Firefox
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
                    element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) { // IE/Edge
                    element.msRequestFullscreen();
                }
                this.fullScreen = true;
            } else {
                // 전체 화면 모드 종료
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { // Firefox
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { // IE/Edge
                    document.msExitFullscreen();
                }
                this.fullScreen = false;
            }
        }, */
        toastMsg (type, title, message) {
            this.$toast(message, {
                title,
                position: 'top-right',
                timeout: 1000,
                icon: true,
                closeOnClick: true,
                type,
                rtl: false
            });
        }
    },
    
}
</script>
<style scoped>
.rotate {
    transform: rotate(90deg);
    transform-origin: center center;
}
</style>